<template>
    <vs-popup class="remise_popup" :title="'Remise sur '+item.name" :active.sync="popupActive">
        <vs-row>
            <vs-col vs-w="6" style="padding-right:10px;">
                <p>En %</p>
                <vs-input class="inputx w-full" type="number" v-model="remise_pourcent" v-on:change="change()" max="100" min="0" />
            </vs-col>
            <vs-col vs-w="6" style="padding-left:10px;">
                <p>En devise</p>
                <vs-input class="inputx w-full" type="number" v-model="remise_money" v-on:change="change()" min="0" />
            </vs-col>
        </vs-row>
        <br/><br/>
        <h3>Nouveau prix TTC: <b>{{ total.toFixed(devise.nb_decimal) }}</b> {{ devise.symbol }}</h3>
        <br/>
        <vs-divider/>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <div></div>
                <vs-button color="success" type="filled" v-on:click="add()" >Activer remise</vs-button>
            </vs-col>
        </vs-row>
    </vs-popup>
</template>

<style lang="scss">
.remise_popup
{
}
</style>

<script>


export default {
    data(){
        return{
            popupActive:false,

            remise_pourcent:'',
            remise_money:'',

            total:0,
            item : {},

            uid: null,
            devise: {}
        }
    },
    mounted() {
        this.$srvApi.Req('get', 'public/currency')
        .then(( rep )=>{
            this.devise = rep.data;
        })
    },
    methods:{
        open( tab_item, index )
        {
            if(tab_item[index].name.indexOf('(')>0)
                return alert('Ce produit contient déja une remise !')

            this.item = tab_item[index]
            this.total = this.item.ttc

            this.remise_pourcent=''
            this.remise_money=''

            this.popupActive = true
        },
        change()
        {
            this.total = this.item.ttc
            
            this.remise_pourcent = Number(this.remise_pourcent)
            this.remise_money    = Number(this.remise_money)

            if( this.remise_pourcent )
                this.total -= (this.remise_pourcent/100)*this.total

            if( this.remise_money )
                this.total -= this.remise_money

            if( this.total < 0 )
            {
                alert("La remise ne peux dépasser 100% du produit");
                this.total = this.item.ttc;
                this.remise_pourcent = 0
                this.remise_money    = 0
                return;
            }
        },
        add()
        {
            this.change()

            if( this.total == this.item.ttc)
                return alert('Aucune remise appliqué !')
            
            this.item.name +=' - Remise '
            if( this.remise_pourcent )
            {
                this.item.name += this.remise_pourcent+'%'
                if( this.remise_money != 0 )
                    this.item.name += ' et '
            }

            if( this.remise_money != 0)
                // this.item.name += this.remise_money+' euros'
                this.item.name += this.remise_money+' '+this.devise.text.toLowerCase()+'s'

            //  this.item.name +=')'

             //new value
             this.item.ttc = this.total
             this.item.ht  = this.item.ttc/( 1+(this.item.tva/100))

             //end
             this.popupActive = false
             this.$emit('refresh')
        }
    }
}

</script>