<template>
    <div class="checkout_fa">

        <MbrHeader ref="MbrHeader" v-bind:uid="$route.params.mbr"/>
        <Remise ref="Remise" v-on:refresh="reloadItem()"/>


        <!-- ======================================================== -->
        <template v-if="page==0">
            <vx-card class="w-full">
                <h2>Veuillez compléter les informations suivantes pour continuer :</h2>
                <vs-divider />
                <MbrFiche  v-bind:uid="$route.params.mbr" ref="MbrFiche" :all="0" @refresh="pageVerifOk" />
            </vx-card>
        </template>

        <!-- ======================================================== -->
        <template v-if="page==1">
            <vs-row>
                <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="4" vs-xs="12">
                    <CatList ref="CatList" v-on:selected="addItem" style="margin-bottom:20px;"/>
                </vs-col>

                <!-- ----------------------------------------- -->
                <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="8" vs-xs="12" class="item_select">
                    <vx-card class="w-full">
                        <h2>{{ getNameMode() }}</h2>
                        <vs-divider />
                        <vs-table v-model="selected" :data="items" class="w-full" noDataText="Cliquez sur les catégories pour ajouter un article.">
                            <!-- header template -->
                            <template slot="header">
                            </template>

                            <!-- header table -->
                            <template slot="thead">
                                <vs-th sort-key="produit">Produit</vs-th>
                                <vs-th sort-key="ht">HT</vs-th>
                                <vs-th sort-key="tva">TVA</vs-th>
                                <vs-th sort-key="ttc">TTC</vs-th>
                                <vs-th ></vs-th>
                            </template>

                            <!--Body table-->
                            <template slot-scope="{data}">
                                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                                    <vs-td :data="data[indextr].name">
                                        <span>{{ StrCapital(data[indextr].name) }}</span>
                                        <template v-if="data[indextr].credit>0">
                                            <br/><small class="blue">+{{ data[indextr].credit }} Crédit
                                                <template v-if="data[indextr].expire>0">
                                                    <br/>pendant {{ data[indextr].expire }} jours
                                                </template>
                                            </small>
                                        </template>
                                    </vs-td>

                                    <vs-td :data="data[indextr].ht">
                                        <span>{{ data[indextr].ht.toFixed(devise.nb_decimal) }} {{ devise.symbol }}</span>
                                    </vs-td>

                                    <vs-td :data="data[indextr].tva">
                                        <span>{{ data[indextr].tva }} %</span>
                                    </vs-td>

                                    <vs-td :data="data[indextr].ttc">
                                        <span>{{ data[indextr].ttc.toFixed(devise.nb_decimal) }} {{ devise.symbol }}</span>
                                    </vs-td>

                                    <vs-td>
                                        <vs-button color="primary" type="filled" class="cursor" v-on:click="addRemise(indextr)" style="padding:5px;margin-right:10px;">R.</vs-button>
                                        <vs-button color="danger" type="filled" class="cursor" v-on:click="removeItem(indextr)" style="padding:5px;">Sup.</vs-button>
                                    </vs-td>

                                </vs-tr>
                            </template>
                        </vs-table>
                        <vs-divider/>
                        <h2 class="align-right">{{ getNameMode() }} total <b>{{ total_ttc.toFixed(devise.nb_decimal) }}</b> {{ devise.symbol }} TTC</h2>
                        <br/>
                        <vs-button color="success" type="filled" class="right" v-on:click="pagePayement()">Passer au paiement</vs-button>
                        <br/><br/>
                    </vx-card>
                </vs-col>
            </vs-row>
        </template>
        
        <!-- ======================================================== -->
        <template v-if="page==2">
            <template v-if="$route.params.type!='re'" >
                <h2 style="float:left;">{{ getNameMode() }}</h2>
                <h2 class="right">Restant à recevoir: <b>{{ restant_ttc.toFixed(devise.nb_decimal) }}</b> {{ devise.symbol }}</h2>
            </template>
            <template v-else >
                <h2 style="float:left;">Reçu</h2>
                <h2 class="right">Montant du reçu: <b>{{ total_paye.toFixed(devise.nb_decimal) }}</b> {{ devise.symbol }}</h2>
            </template>

            <br/>
            <vs-divider/>
            <br/>
            <div class="vx-row">
                <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base" v-for="paye in list_payement" :key="paye.name">
                    <vx-card>
                        <div class="flex justify-between flex-wrap">
                            <h2 class="right">{{ paye.name }}</h2>
                            <vs-icon :icon="paye.icon" size="large" style="color:#DDDDDD;"></vs-icon>
                        </div>

                        <template v-if="paye.param =='pay_dif' && $route.params.type=='re'">
                            <br/>
                            <p style="color:#8A2BE2">Cette opération impactera la créance du client de {{ printReValue( paye.value ) }} {{ devise.text+'s' }}</p>
                        </template>
                        <template v-else>
                            <br/>
                            <vs-input class="inputx w-full" type="number" v-model="paye.value" min='0' v-on:change="updatePaye()" />
                        </template>
                    </vx-card>
                </div>
            </div>
            <vs-divider/>
            <vs-button color="primary" type="filled" v-on:click="pageRetour()" >Retour</vs-button>
            <vs-button color="success" type="filled" class="right" v-on:click="pageRecap()">Finalisation</vs-button>
        </template>

        <!-- ======================================================== -->
        <template v-if="page==3">
            <h2>Finalisation {{ getNameMode() }}:</h2>
            <vs-divider/>
            <br/>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="8" vs-sm="12" vs-xs="12">
                    <vx-card class="w-full">
                        <vs-table v-model="selected" :data="items" class="w-full" noDataText="Aucun résultat">

                            <!-- header table -->
                            <template slot="thead">
                                <vs-th sort-key="produit">Produit</vs-th>
                                <vs-th sort-key="ht">HT</vs-th>
                                <vs-th sort-key="tva">TVA</vs-th>
                                <vs-th sort-key="ttc">TTC</vs-th>
                            </template>

                            <!--Body table-->
                            <template slot-scope="{data}">
                                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                                    <vs-td :data="data[indextr].produit">
                                        <span>{{ StrCapital(data[indextr].name) }}</span>
                                    </vs-td>

                                    <vs-td :data="data[indextr].ht">
                                        <span>{{ data[indextr].ht.toFixed(devise.nb_decimal) }} {{ devise.symbol }}</span>
                                    </vs-td>

                                    <vs-td :data="data[indextr].tva">
                                        <span>{{ data[indextr].tva }} %</span>
                                    </vs-td>

                                    <vs-td :data="data[indextr].ttc">
                                        <span>{{ data[indextr].ttc.toFixed(devise.nb_decimal) }} {{ devise.symbol }}</span>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                        <vs-divider/>
                        <span class="right">Total: {{ total_ttc.toFixed(devise.nb_decimal) }} {{ devise.symbol }}</span>
                    </vx-card>
                    <br/>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="4" vs-sm="12" vs-xs="12" class="item_select">
                    <vx-card class="w-full">
                        <ul v-for="paye in list_payement" :key="paye.name" >
                            <li>{{ paye.name }} :<span class="right">{{ Number(paye.value).toFixed(devise.nb_decimal) }} {{ devise.symbol }}</span></li>
                        </ul>
                        <vs-divider/>
                        <span>Total: <span class="right">{{ Number(total_paye).toFixed(devise.nb_decimal) }} {{ devise.symbol }}</span></span>
                    </vx-card>
                    <br/>
                </vs-col>
            </vs-row>
            <br/>
            <template v-if="$route.params.type=='fa' && total_credit>0">
                <vx-card class="w-full">
                    <iframe v-bind:src="cgv_path" class="w-full" height="400" frameBorder="0"></iframe>
                    <vs-divider/>
                    <vs-checkbox color="success" v-model="cgv_valide">Je reconnais avoir pris connaissance des CGV</vs-checkbox>
                </vx-card>
                <br/>
                <vx-card class="w-full">
                    <vs-button color="primary" type="border" class="right" v-on:click="SignClear()">Nettoyer</vs-button>
                    <h3>Signature:</h3>
                    <vs-divider/>
                    <VueSignaturePad
                        id="signature"
                        width="100%"
                        height="300px"
                        ref="signaturePad"
                    />
                </vx-card>
            </template>

            <template v-if="$route.params.type=='av'">
                <vx-card class="w-full">
                    Raison de l'avoir:<br/>
                    <vs-textarea v-model="msg" />
                </vx-card>
            </template>
            <br/>
            <br/>
            <vs-button color="primary" type="filled" v-on:click="pageRetour()" >Retour</vs-button>
            <vs-button color="success" type="filled" class="right nowrap" v-on:click="validFacture()" >Créer {{ getNameMode() }}</vs-button>
        </template>
        <!-- ======================================================== -->
        <template v-if="page==4">
            <h2>Mollie paiement</h2>
            <vs-divider/>
            <br/>
            <vx-card>
                <p>IBAN Client:</p>
                <vs-input class="inputx w-full" v-model="mollie_iban" /><br/>
                <p>Nombre mois de répétition:</p>
                <vs-input class="inputx w-full" type="number" v-model="mollie_repeat" min="1" /><br/>

                <template v-if="mollie_repeat">
                    <div v-for="pre in list_payement" :key="pre.param">
                        <template v-if="pre.param=='pay_prele'">
                            <p class="center text-center">Prélévement total: {{ parseFloat(pre.value).toFixed(devise.nb_decimal) }} {{ devise.symbol }}, Soit <strong>{{ (parseFloat(pre.value)/parseFloat(mollie_repeat)).toFixed(devise.nb_decimal) }}</strong> {{ devise.symbol }} pendant <strong>{{ mollie_repeat }}</strong> mois</p>
                        </template>
                    </div>
                </template>
            </vx-card>
            <br/>
            <br/>
            <vs-button color="primary" type="filled" v-on:click="pageRetour()" >Retour</vs-button>
            <vs-button color="success" type="filled" class="right nowrap" v-on:click="mollieValidation()" >Enregistrer prélévement</vs-button>
        </template>

        <!-- ======================================================== -->
        <template v-if="page==5">
            <vx-card>
                <h2>Traitement {{ getNameMode() }} en cours, veuillez patientez ... </h2>
            </vx-card>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.blue{
    color:blue;
}
.right{
    display:inline-block;
    float:right;
}
.item_view{
    padding:5px;
}
.item_view:hover{
    background-color:#ededed;
}
.align-right{
    text-align:right;
}
.vs-collapse{
    background-color:white;
}

@media only screen and (min-width: 900px)
{
    .item_select{
        padding-left:20px;
    }
}
@media only screen and (max-width: 899px)
{
    .item_select{
        margin-top:20px;
    }
}
</style>

<script>
import Utils from '@/assets/utils/utils'
import Fdate from '@/assets/utils/fdate';

import MbrHeader from '@/components/member/header.vue'
import CatList from '@/components/checkout/categorie.vue'
import Remise from '@/components/checkout/remise.vue'
import MbrFiche from '@/components/member/ufiche.vue'

import autoRefresh from '@/assets/utils/autoRefresh'

import Invoice  from '@/database/models/invoice'
import Member  from '@/database/models/member'

export default {
    components:{
        MbrHeader,
        CatList,
        Remise,
        MbrFiche,
    },
    data(){
        return{
            page:0,

            selected:[],
            items:[],
            total_ttc:0,
            total_paye:0,
            
            restant_ttc:0,
            total_credit:0,

            cgv_path:'',
            cgv_valide:0,

            list_payement:[{
                name : 'Espèces',
                param: 'pay_money',
                icon : 'toll',
                value: '',
            },{
                name : 'CB',
                param: 'pay_cb',
                icon : 'credit_card',
                value: '',
            },{
                name : 'Chèque',
                param: 'pay_check',
                icon : 'note',
                value: '',
            },{
                name : 'Prélévement',
                param: 'pay_prele',
                icon : 'event_available',
                value: '',
            },{
                name : 'Différé',
                param: 'pay_dif',
                icon : 'update',
                value: '',
            }],

            mollie_iban:'',
            mollie_repeat:'',
            mollie_id : undefined,
            mollie_secu : true,


            json_check:[],
            msg:'',

            signature:undefined,

            devise: {}
        }
	},
	mounted:function()
	{
        this.$srvApi.Req('get', 'public/currency')
        .then(( rep )=>{
            this.devise = rep.data;
        })

        //verif data membre
        this.pageVerifOk()

        //autoRefresh
		autoRefresh.SetCallback(()=>{
            if(this.$refs.MbrHeader.load)
            {
                this.$refs.MbrHeader.load( this.$route.params.mbr )
            }
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            this.selected=[],
            this.items=[]
            this.total_ttc=0
            this.total_paye=0
            
            this.restant_ttc=0
            this.total_credit=0

            this.cgv_path=''
            this.cgv_valide=0

            for(var i=0; i<this.list_payement.length;i++)
                this.list_payement[i].value = ''

            this.page = 0
            this.json_check = []
            this.msg = ''
            this.mollie_iban=''
            this.mollie_repeat=''
            this.mollie_id = undefined


            //verif data membre
            this.pageVerifOk()
        }
    },
    methods:{
        StrCapital:function(str){
            return Utils.StrCapital(str);
        },
        getNameMode:function()
        {
            let rep = "?"
            if(this.$route.params.type=="fa")
                rep = "Facture"
            if(this.$route.params.type=="av")
                rep = "Avoir"
            if(this.$route.params.type=="re")
                rep = "Reçu" 
            return rep
        },
        addRemise( index )
        {
            this.$refs.Remise.open( this.items, index )
        },
        pageVerifOk()
        {
            window.scrollTo(0,0);
            this.page = 0
            Member.ficheMinComplete(this.$route.params.mbr)
            .then(()=>{
                //Ok fiche membre on passe au page suivante
                this.page = 1
                if(this.$route.params.type=="re")
                    this.page = 2 
            })
            .catch((msg)=>{
                //Message erreur fiche membre
                this.$refs.MbrFiche.setMsg( msg )
                //on remet la meme page
                this.page = 0
            })
        },
        addItem( item )
        {
            //Nb item a ajouté
            for( var i=0; i<item.nb; i++ )
            {
                // console.log(item)
                //Item
                this.items.push({
                    uid            : item.uid,
                    name           : item.produit,
                    description    : item.description,
                    credit         : item.credit ,
                    expire         : item.day    ,
                    remise         : 0           ,
                    tva            : item.tva    ,
                    ttc            : item.ttc    ,
                    ht             : item.ht     ,
                    machine        : item.machine,
                    compte_produit : item.compte_produit,
                    compte_tva     : item.compte_tva,
                    type           : item.type
                });
                //Data calc
                this.restant_ttc  += item.ttc
                this.total_ttc    += item.ttc
                this.total_credit += item.credit
            }
        },
        removeItem( posi )
        {
            this.restant_ttc  -= this.items[posi].ttc
            this.total_ttc    -= this.items[posi].ttc
            this.total_credit -= this.items[posi].credit
            this.items.splice(posi,1)
        },
        reloadItem()
        {
            this.restant_ttc  = 0
            this.total_ttc    = 0
            this.total_credit = 0  
            for( var i in this.items )
            {
                this.restant_ttc  += this.items[i].ttc
                this.total_ttc    += this.items[i].ttc
                this.total_credit += this.items[i].credit   
            }
        },
        pageRetour(){
            window.scrollTo(0,0);
            if(this.page>1)
                this.page--
        },
        pagePayement()
        {
            window.scrollTo(0,0);
            if( this.items.length>0)
                this.page = 2;
            else
                alert('Aucun produit séléctionné !')
        },
        updatePaye()
        {
            this.restant_ttc = 0
            
            if(this.$route.params.type!="re")
            {
                for( var p=0; p< this.items.length; p++)
                    this.restant_ttc += parseFloat(this.items[p].ttc)

                for( var i=0; i<this.list_payement.length; i++)
                if( Number(this.list_payement[i].value))
                    this.restant_ttc -= parseFloat(Number(this.list_payement[i].value))

                this.total_paye = 0
                for( i=0; i<this.list_payement.length; i++)
                    this.total_paye += parseFloat(Number(this.list_payement[i].value))
            }
            else
            {
                this.total_paye = 0
                for( i=0; i<this.list_payement.length; i++)
                if( Number(this.list_payement[i].value))
                if( this.list_payement[i].param != "pay_dif")
                    this.total_paye += parseFloat(Number(this.list_payement[i].value))
                
                for( i=0; i<this.list_payement.length; i++)
                if( this.list_payement[i].param == "pay_dif")
                    this.list_payement[i].value = this.total_paye*-1
            }
        },
        pageRecap()
        {
            window.scrollTo(0,0);
            //pour avoir des recu negatif et positif
            if( this.$route.params.type == "re" )
            {
                this.page = 3;
                return
            }

            this.total_paye  = Utils.NumberTwoVirgule( this.total_paye )
            this.total_ttc   = Utils.NumberTwoVirgule( this.total_ttc )
            this.restant_ttc = Utils.NumberTwoVirgule( this.restant_ttc )

            //Bug enlevé ( aucune idée de pourquoi ca fait ca dans un type Number ??? )
            //console.log( this.restant_ttc.toString() )
            //console.log( this.total_paye, this.total_ttc, this.restant_ttc )

            //verfier si réglement OK
            if( this.restant_ttc == 0)
            if( this.total_paye == this.total_ttc)
            {
                this.cgv_path = this.$srvApi.buildUrl('studio/cgv/print/'+Fdate.getInputDate( new Date() ))

                //numéro de chéque ?
                this.json_check = []
                for( let i=0; i<this.list_payement.length; i++)
                if( this.list_payement[i].param == "pay_check")
                if( this.list_payement[i].value != 0 )
                {
                    let ckeck = prompt("Numéro de chèque séparé par une virgule :")
                    let tbuf = ckeck.split(',')
                    for(var p=0; p<tbuf.length; p++)
                        this.json_check.push({'Cheque':tbuf[p]})
                }

                this.page = 3;
                window.scrollTo(0,0);
                return
            }

            alert('Réglement non complet !')
        },
        SignClear()
        {
            this.$refs.signaturePad.clearSignature()
        },
        validFacture()
        {
            this.signature = undefined

            //si besoin d'une validation CGV et signature
            if(this.total_credit>0)
            if(this.$route.params.type=="fa")//Uniquement facture
            {
                if(!this.cgv_valide)
                    return alert('CGV non validé !')

                if(this.$refs.signaturePad.isEmpty() == true)
                    return alert('Aucune signature !')
                
                //get signature
                this.signature = this.$refs.signaturePad.saveSignature().data
            }

            //message obligatoire si avoir
            if(this.msg.length<5)
            if(this.$route.params.type=="av")
                return alert('Raison de l\'avoir obligatoire !');

            //si autre chose que une facture
            if(this.$route.params.type!="fa")
                return this.enregistrer()

            //verifier si on saute ou pas mollie
            for( let i=0; i<this.list_payement.length; i++)
            if( this.list_payement[i].param == "pay_prele")
            if( this.list_payement[i].value == 0 )
                return this.enregistrer()

            //Page molie
            if( this.page == 3 )//verrou éviter double click
            {
                if(window.confirm('Voulez-vous utiliser Mollie ?'))
                    this.page = 4
                else
                    return this.enregistrer()
                window.scrollTo(0,0);
            }
        },      
        mollieValidation()
        {
            let prelevement = 0
            //get prélévement
            for( let i=0; i<this.list_payement.length; i++)
            if( this.list_payement[i].param == "pay_prele")
             prelevement = this.list_payement[i].value

            if(prelevement==0)
             return this.enregistrer()

            //verif IBAN
            if(this.mollie_iban.length <= 14 )
                return alert('IBAN non complet !')

            //verif repat
            if(Number(this.mollie_repeat) < 1 )
                return alert('Répétition minimum: 1')

            //mollie secu
            if(this.mollie_secu==false)
            {
                alert('Un prélèvement a déjà été envoyé sur Mollie, connectez-vous pour vérifier');
                return this.enregistrer();
            }
            this.mollie_secu = false;

            //payement mode mollie IBAN
            this.$srvApi.Req('post','mollie/iban',{
                member   : this.$route.params.mbr,
                month    : Number(this.mollie_repeat),
                amount   : prelevement,
                iban     : this.mollie_iban,
            })
            .then((rep)=>{
                if( rep.data.indexOf('sub_') == 0 )
                {
                    this.mollie_id = rep.data
                    this.enregistrer()
                }
                else
                    alert( rep.data.split('Documentation')[0] );
            })
            .catch((err)=>{
                alert('Erreur API mollie')
                alert( err )
            })
        },
        enregistrer()
        {
            //clear payement
            let paye = []
            for( let i=0; i<this.list_payement.length;i++)
            if( this.list_payement[i].value!='')
            if( this.list_payement[i].value!=0)
            {
                this.list_payement[i].value = parseFloat( Number(this.list_payement[i].value))
                paye.push(this.list_payement[i])
            }

            //clear item
            let fitems = []
            for( let i=0; i<this.items.length;i++)
                fitems.push(this.items[i])

            //Payement mode avoir
            if(this.$route.params.type=="av")
            {
                for( let i=0; i<paye.length;i++)
                    paye[i].value = paye[i].value*-1
                
                for( let i=0; i<fitems.length;i++)
                {
                    fitems[i].credit *= -1
                    fitems[i].expire *= -1
                    fitems[i].ht     *= -1
                    fitems[i].ttc    *= -1
                    fitems[i].tva    *= -1
                }
            }

            //Format fonction
            let data = {
                type      : this.$route.params.type,
                member    : this.$route.params.mbr,
                payement  : JSON.stringify(paye),
                item      : JSON.stringify(fitems),
                signature : this.signature,
            }

            //si json utilisé
            if(this.json_check)
                data.json_check = JSON.stringify(this.json_check)

            //message
            if(this.msg)
                data.msg = this.msg

            //so mollie utilisé
            if(this.mollie_id)
            {
                data.mollie_id     = this.mollie_id
                data.mollie_repeat = Number(this.mollie_repeat)
            }

            //page traitement facture
            this.page = 5
            window.scrollTo(0,0);

            // console.log(data)

            //Envoi de la facture
            Invoice.add(data)
            .then((uid_caddy)=>{
                alert('Création '+this.getNameMode()+" réussi !")
                //Go fiche membre
                this.$router.push("/admin/invoice/"+uid_caddy).catch(() => {})
            })
            .catch((rep)=>{
                alert(rep.message)
                //Go fiche membre
                this.$router.push("/admin/member/"+this.$route.params.mbr).catch(() => {})
            })
        },
        printReValue( value )
        {
            if(value!="" ) 
                return value.toFixed(this.devise.nb_decimal)
            return "0.00"
        }
    }
};

</script>